<template>
  <CompanyHeader title="הסטורית אישורי תשלום">
    <template v-slot:content>
      <v-container>
        <v-card>
          <v-card-title class="d-flex align-center">
            <v-row>
              <v-col :cols="inputSearch ? 8 : 2">
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  icon
                  :label="inputSearch ? 'חפוש' : ''"
                  single-line
                  hide-details
                  @focus="inputSearch = true"
                  @blur="inputSearch = false"
                ></v-text-field>
              </v-col>
              <v-col class="center-all">
                <DateRangePicker
                  v-model="dateRange"
                  label="בחר טווח תאריכים"
                  @approved="loadData"
                />
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="credits"
            :search="search"
            :loading="loading"
            :items-per-page="50"
          >
            <template v-slot:item.amount="{ item }">
              {{ item.amount }}
              <v-icon x-small color="secondary">mdi-currency-ils</v-icon>
            </template>
            <template v-slot:item.createdAt="{ item }">
              {{ dateTimeFormat(item.createdAt) }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon color="success" @click="openInfo(item)">
                mdi-information
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
        <payment-confirmation-handler
          v-if="openDialog"
          v-model="openDialog"
          :credit="current"
        />
      </v-container>
    </template>
  </CompanyHeader>
</template>

<script>
import PaymentConfirmationHandler from "@/components/dialogs/PaymentConfirmationHandler.vue";
import CompanyHeader from "@/components/company/CompanyHeader.vue";
import DateRangePicker from "@/components/generics/DateRangePicker.vue";
import dayjs, { dateTimeFormat } from "@/utils/dayjs";

export default {
  name: "PaymentConfirmations",
  components: {
    DateRangePicker,
    CompanyHeader,
    PaymentConfirmationHandler,
  },
  data() {
    return {
      loading: false,
      inputSearch: false,
      dateSelected: false,
      headers: [
        { text: "שם פרטי", value: "user.firstName" },
        { text: "שם משפחה", value: "user.lastName" },
        { text: "סכום", value: "amount", width: "20%" },
        { text: "תאריך", value: "createdAt" },
        { text: "פעולות", value: "actions", width: "2%" },
      ],
      dateRange: [
        dayjs().subtract(3, "day").format("YYYY-MM-DD"),
        dayjs().format("YYYY-MM-DD"),
      ],
      current: null,
      search: "",
      openDialog: false,
    };
  },
  computed: {
    credits() {
      return this.$store.getters.credits;
    },
  },
  methods: {
    dateTimeFormat,
    async loadData() {
      this.loading = true;
      await this.$store.dispatch("credit/index/company", {
        status: ["approved", "used"],
        dateRange: this.dateRange,
      });

      this.loading = false;
    },
    openInfo(item) {
      this.current = item;
      this.openDialog = true;
    },
  },
  async mounted() {
    await this.loadData();
  },
};
</script>
