<template>
  <div>
    <my-dialog title="פרטי הזיכוי" v-model="openModal">
      <template v-slot:content>
        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="">שם</th>
                <th class="">סכום</th>
                <th class="">טלפון</th>
                <th class="">כתובת</th>
                <th class="">תאריך פניה</th>
                <th class="">תאריך אישור</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="">
                  <div>{{ credit.user.firstName }}</div>
                  <div>{{ credit.user.lastName }}</div>
                </td>
                <td class="">
                  <span>
                    {{ credit.amount }}
                    <v-icon x-small color="secondary">mdi-currency-ils</v-icon>
                  </span>
                </td>
                <td class="">
                  <a :href="`tel:+${credit.user.phone}`">
                    {{ credit.user.phone }}
                  </a>
                </td>
                <td class="">
                  <div>{{ credit.user.address }}</div>
                </td>
                <td class="">
                  <div>
                    {{ dateTimeFormat(credit.createdAt) }}
                  </div>
                </td>
                <td class="">
                  <div>
                    {{ dateTimeFormat(credit.approvedAt) }}
                  </div>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from "@/components/generics/MyDialog.vue";
import { dateTimeFormat } from "@/utils/dayjs";
export default {
  name: "payment-confirmation-handler",
  components: { MyDialog },
  props: {
    value: Boolean,
    credit: Object,
  },
  computed: {
    openModal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
  methods: {
    dateTimeFormat,
  },
};
</script>
<style lang="css" scoped>
a {
  text-decoration: none;
}
</style>
